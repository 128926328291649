import lEn from './en'
import lKr from './kr'
import detectBrowserLanguage from 'detect-browser-language'

class Localization {
	constructor(options) {
		this.languagePack = lEn
		this.visibleLanguage = "en"
		console.log(options)
		this.setLanguage(options.lang || detectBrowserLanguage())
	}

	getOriginalLanguage = () => {
		return this.language
	}

	getVisibleLanguage = () => {
		return this.visibleLanguage
	}

	setLanguage = (lang) => {
		console.log(lang)
		this.language = lang
		if (lang === "ko-KR" || lang === "ko" || lang === "KR") {
			this.visibleLanguage = "ko"
			this.languagePack = lKr
		}
		else if (lang === "en-US" || lang === "en" || lang === "US") {
			this.visibleLanguage = "en"
			this.languagePack = lEn
		}
	}

	getStrings = () => {
		return this.languagePack
	}
}

export default Localization