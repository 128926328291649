import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { Delete, FileCopy } from '@material-ui/icons'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const styles = theme => ({
	content: {
		height: 60,
		padding: 5,
	},
	image: {
		height: 60,
		width: 80,
		objectFit: 'fill',
		float: 'left',
	},
	text: {
		textSize: 12,
		float: 'none',
	},
});

class ListItem extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			regexp: props.regexp,
			data: props.data,
			isCopy: props.isCopy,
			isDelete: props.isDelete,
			onClickCopy: props.onClickCopy,
			onClickDelete: props.onClickDelete,
		}
	}

	renderChildren() {
		let { children, ...acyclicalProps } = this.props.children
		return (React.cloneElement(children, acyclicalProps))
	}

	render() {
		const { regexp, classes, subHighlight } = this.props;
		const title = this.props.data.snippet.title
		const image = this.props.data.snippet.thumbnails.default && this.props.data.snippet.thumbnails.default.url;
		const __publishedAt = this.props.data.snippet.publishedAt;
		let resultTitle = title;
		let publishedAt = __publishedAt;
		if (subHighlight) {
			publishedAt = `<b>${__publishedAt}</b>`
		} else {
			if (regexp) {
				try {
					resultTitle = title.replace(regexp, function (str) { return '<b>' + str + '</b>' });
				} catch (e) {
					resultTitle = title;
				}
			}
		}
		return (
			<Paper className={classes.content}>
				<Grid container>
					<Grid item xs={2} style={image ? { display: 'flex' } : { display: 'block', textAlign: 'center' }}>
						{image 
						? <img alt="Thumbnail" src={image} className={classes.image} /> 
						: <HighlightOffIcon style={{color: 'red'}} />
						}
						
					</Grid>
					<Grid item xs={9} style={{ textAlign: 'left' }}>
						<div className={classes.text} dangerouslySetInnerHTML={{ __html: resultTitle }} />
						<div className={classes.text} dangerouslySetInnerHTML={{ __html: publishedAt }} />
					</Grid>
					{/* justify="center"  */}
					{/* minHeight: '100vh', */}
					<Grid item xs={1} style={{ textAlign: 'center', display: 'flex' }}>
						{/* {this.renderChildren()} */}
						{/* {React.cloneElement(this.props.children, { data: this.props })} */}
						{/* {this.props.children} */}
						<IconButton
							aria-label="copy"
							className={classes.margin}
							onClick={(event) => { if (this.state.isCopy && this.props.onClickCopy) this.props.onClickCopy(this.props.data) }}
							style={{ display: this.state.isCopy ? 'inline' : 'none' }}
						>
							<FileCopy fontSize="small" />
						</IconButton>
						<IconButton
							aria-label="delete"
							className={classes.margin}
							onClick={(event) => { if (this.state.isDelete && this.props.onClickDelete) this.props.onClickDelete(this.props.data) }}
							style={{ display: this.state.isDelete ? 'inline' : 'none' }}
						>
							<Delete fontSize="small" />
						</IconButton>
					</Grid>
				</Grid>
			</Paper>
		);
	}
}

export default withStyles(styles)(ListItem);