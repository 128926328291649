import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom' //, Redirect
import Main from "./router/Main"
import YoutubeApi from './module/YoutubeApi'
import { CookiesProvider } from 'react-cookie';
import { SnackbarProvider } from 'notistack';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";

class App extends React.Component {
	constructor(props) {
		super(props)
		if (!firebase.apps.length) {
			const firebaseConfig = {
				apiKey: "AIzaSyAs4N1TaQpcZecMD3VE_r2UijtY31BR40M",
				authDomain: "playlist-sort-291810.firebaseapp.com",
				projectId: "youtube-playlist-sort-291810",
				storageBucket: "youtube-playlist-sort-291810.appspot.com",
				messagingSenderId: "1091510165421",
				appId: "1:1091510165421:web:ff845ba2a8cab6db444b24",
				measurementId: "G-8QW3B37VF3",
			};
			//let app = firebase.initializeApp(firebaseConfig);
			firebase.initializeApp(firebaseConfig);
		}
		
		const clientId = "1091510165421-4tebla9s2b4pbmu1soemlmprp3f9f8d8.apps.googleusercontent.com";
		this.state = {
			youtubeApi: new YoutubeApi({
				oAuth2Id: clientId,
				clientId: process.env.REACT_APP_YOUTUBE_KEY,
			}),
			isSignedIn: false
		}
	}

	render() {
		// const { classes } = this.props;
		return (
			<CookiesProvider>
				<SnackbarProvider maxSnack={5}>
					<Router>
						{/* <Header /> */}
						{/* <Route exact path="/" component={Main} /> */}
						<Route exact path="/" render={(props) => <Main {...this.state} {...props} />} />
					</Router>
				</SnackbarProvider>
			</CookiesProvider>
		);
	}
}

export default App;
