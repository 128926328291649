import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField'
//import FormControl from '@material-ui/core/FormControl'
import { debounce } from 'lodash';

const styles = theme => ({

});

class DebounceTextField extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			value: props.value
		}
		this.changeForParent = debounce(this.changeForParent, 200)
	}

	componentDidMount() {
		if (this.props.changeValue) this.props.changeValue(this.changeFromParent)
	}

	changeForParent = (value) => {
		if (this.props.onChange) this.props.onChange(value)
	}

	changeFromParent = (value) => {
		this.setState({ value: value })
	}

	changeValue = (value) => {
		this.setState({ value: value })
		this.changeForParent(value)
	}

	render() {
		//const { classes } = this.props;
		return (
			// <FormControl fullWidth variant="outlined">
				<TextField
					id={this.props.id}
					label={this.props.label}
					style={this.props.style}
					value={this.state.value}
					onChange={(event) => this.changeValue(event.target.value)}
					InputProps={this.props.InputProps}
					InputLabelProps={{style: this.props.InputLabelProps}}
					helperText={this.props.helperText} />
			// </FormControl>
		);
	}
}

export default withStyles(styles)(DebounceTextField);