import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl'
import { Helmet } from 'react-helmet'
import Button from '@material-ui/core/Button'
import DraggableList from '../component/DraggableList'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
// import api from '../module/YoutubeApi'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Dialog, DialogTitle, CircularProgress, DialogContent, DialogActions, DialogContentText } from '@material-ui/core'
import moment from 'moment'
import DebounceTextField from '../component/DebounceTextField'
import Typography from '@material-ui/core/Typography'
import { createMuiTheme } from '@material-ui/core/styles';
import detectBrowserLanguage from 'detect-browser-language'
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import Localization from '../locale/Localization'
import { withSnackbar } from 'notistack';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import FilterListIcon from '@material-ui/icons/FilterList';
import FreeBreakfastIcon from '@material-ui/icons/FreeBreakfast';
import ListItem from '../component/ListItem'
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
//import { gapi } from 'gapi-script';
//key=AIzaSyBP7Mn0UbF_qB-zBw6Oe_7bAmdT66ovPqc
//https://www.youtube.com/watch?v=NkBrIRKmdCI&list=PL5jCWAVC_lq6maX-LT3QpR8VDxEfZhwPR&ab_channel=%EB%82%9C%EC%95%84%EB%A9%94%EB%A6%AC%EC%B9%B4%EB%85%B8
const styles = theme => ({
	appBar: {
		maxWidth: 1300,
		height: 100,
		margin: "auto",
		display: "block",
		lineHeight: "100px",
		paddingLeft: 12,
		paddingRight: 12,
	},
	appBarMenu: {
		// position: "absolute",
		// transform: "translateY(-50%)",
		// top: "50%",
		// right: "0%",
		display: "inline-flex",
		height: 100,
		float: "right",
	},
	mainBar: {
		backgroundColor: "#232428",
		height: 290,
		textAlign: "center",
	},
	root: {
		flexGrow: 1,
		// backgroundColor: "#f5f5f5"
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
	},
	contentWrapper: {
		//justifyContent: 'center',
		//display: 'flex',
		// minHeight: 400,
		padding: 20,
	},
	introPaper: {
		minHeight: 260,
	},
	introPaperTitle: {
		fontFamily: "Noto Sans CJK KR",
		fontSize: 20,
		fontWeight: "bold",
		color: "#2F2F2F",
		paddingTop: 16,
		lineHeight: "36px",
		textAlign: 'center'
	},
	introPaperIconWrapper: {
		minHeight: 160,
		width: '100%',
		justifyContent: 'center',
		display: 'flex',
		alignItems: 'center'
	},
	introPaperDescription: {
		fontFamily: "Noto Sans CJK KR",
		fontSize: 16,
		fontWeight: "light",
		color: "#2F2F2F",
		paddingTop: 4,
		textAlign: 'center'
	},
	content: {
		padding: 40,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
	margin: {
		margin: theme.spacing(1),
	},
	progressDialog: {
		textAlign: 'center',
		padding: 10,
		display: 'inline-block',
		minWidth: 200,
		minHeight: 60,
	},
	notchedOutline: {
		borderWidth: "1px",
		borderColor: "yellow !important"
	},
	underline: {
		'border': {
			borderBottom: '1px solid rgba(255, 255, 255, 255)'
		},
		'&:before': {
			borderBottom: '1px solid rgba(255, 255, 255, 255)'
		},
		'&:after': {
			borderBottom: `2px solid rgba(255, 255, 255, 255)`
		},
		'&:hover': {
			borderBottom: `2px solid rgba(255, 255, 255, 255)`
		},
		'&:focus': {
			borderBottom: `2px solid rgba(255, 255, 255, 255)`
		},
		'&:hover:not($disabled):not($focused):not($error):before': {
			borderBottom: `2px solid rgba(255, 255, 255, 255)`
		},
	},
	listWrapper: {
		padding: 8,
	}
});


class Main extends React.Component {
	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props)
		let sortReg = "\\d+";
		let filterReg = ".+"
		const { cookies } = props;
		this.localeUtil = new Localization({ lang: cookies.get("language") || detectBrowserLanguage() })
		this.analytics = firebase.app().analytics()
		this.props.youtubeApi.setOnSignInStatusChangeListener(this.onSignInStatusChange)
		this.state = {
			playlistId: "PL4bDVqNQ4hSQAgaALQs3NnMq7Ru3CdouN",
			// playlistId: "PL5jCWAVC_lq7WNAh-yMiMKYT1r0YT2bLH",
			// playlistId: "PLxy7g6R6SsL0kTLKe1J1QNuHll7DxackB",
			regex: sortReg,
			regexp: new RegExp(sortReg, 'gm'),
			originList: [],
			items: [],
			copyType: 0,
			filter: filterReg,
			filterExp: new RegExp(filterReg, 'gm'),
			sortType: 0,
			sortSubHighlight: false,
			playListTitle: `[XORT] ${moment().format('YYYYMMDD')}`,
			playListDescription: `Created by Xort - ${Date()}`,
			progress: false,
			progressTitle: "Hello World",
			language: this.localeUtil.getVisibleLanguage(),
			strings: this.localeUtil.getStrings(),
			isSignIn: this.props.youtubeApi.isSignedIn(),
			privacyState: cookies.get("privacyState") || 'private',
			isLoadSuccess: false,
			listId: "",
			cookieAccept: cookies.get("cookieAccept") || false
		}
		console.log("Browser Language : " + detectBrowserLanguage() + " / Cookie Language : " + cookies.get("language") + " / State Language : " + this.state.language + " / Util lang : " + this.localeUtil.getVisibleLanguage())
	}

	load = async () => {
		//https://www.youtube.com/watch?v=NkBrIRKmdCI&list=PL5jCWAVC_lq6maX-LT3QpR8VDxEfZhwPR&ab_channel=%EB%82%9C%EC%95%84%EB%A9%94%EB%A6%AC%EC%B9%B4%EB%85%B8
		const { strings } = this.state
		this.showProgress(strings.step1LoadList)
		console.log(this.state.playlistId)
		let regex = /^.*?(?:list)=(.*?)(?:&|$)/g
		let result = regex.exec(this.state.playlistId);
		let listId = this.state.playlistId
		console.log(result)
		if (result !== null && result.length >= 2) listId = result[1];
		console.log(listId)
		try {
			let list = await this.props.youtubeApi.getPlaylistAll(listId)
			this.analytics.logEvent('search', { search_term: this.state.playlistId })
			console.log(`Load items : ${list.length}`)
			console.log(list)
			this.setState({
				originList: list,
				listId: listId,
				isLoadSuccess: true,
			})
			this.closeProgress()
		} catch (e) {
			let exception = e.res
			console.log(e)
			if (!exception) {
				this.showToast(this.state.strings.errorLoadList, 'error')
				this.closeProgress()
				this.analytics.logEvent('load_failed', { listId: listId, error: e })	
				return;
			}
			console.dir(e)
			let body = exception.json()
			console.log(body)
			if (body.status === 403 
				&& body.result 
				&& body.result.error 
				&& Array.isArray(body.result.error.errors) 
				&& body.result.error.errors.length > 0 
				&& body.result.error.errors[0].reason === "quotaExceeded") {
				this.showToast(strings.errorApiLimit, 'error', true)
				this.analytics.logEvent('load_failed_limit_exceed', { listId: listId, error: e })	
			} else if (e.name === "StatusError" && e.statusCode === 404) {
				this.showToast(this.state.strings.errorLoadList, 'error')
				this.closeProgress()
				this.analytics.logEvent('load_failed', { listId: listId, error: e })	
			} else {
				console.dir(e)
				console.error(e)
				this.closeProgress()
				this.showToast(this.state.strings.errorLoadList, 'error')
				this.analytics.logEvent('load_failed', { listId: listId, error: e })	
			}
		}
	}

	onAcceptCookie = () => {
		const { cookies } = this.props
		this.setState({cookieAccept: true})
		cookies.set('cookieAccept', true, { path: '/' })
	}

	toggleAuth = () => {
		if (this.props.youtubeApi.isSignedIn()) {
			this.props.youtubeApi.signOut();
		} else {
			this.props.youtubeApi.signIn();
		}
	}

	onSignInStatusChange = (isSignIn) => {
		console.log(isSignIn)
		this.setState({ isSignIn: isSignIn })
	}

	onChangeLanguage = (lang) => {
		const { cookies } = this.props
		cookies.set('language', lang, { path: '/' })
		this.localeUtil.setLanguage(lang)
		this.setState({
			language: this.localeUtil.getVisibleLanguage(),
			strings: this.localeUtil.getStrings(),
		})
		this.analytics.logEvent("language_change", { language: lang, visibleLanguage: this.localeUtil.getVisibleLanguage() })
		console.log(`lang : ${lang} / util lang : ${this.localeUtil.getVisibleLanguage()} / Cookie lang : ${cookies.get("language")}`)
	}

	changeRegex = (value) => {
		let regexp = null
		try {
			regexp = new RegExp(value, 'gm')
			console.log("success")
		} catch (e) {
			regexp = null
			console.log("false")
		}
		this.setState({ regex: value, regexp: regexp })
	}

	getSortRank = (value) => {
		let matchCase = value.match(this.state.regexp);
		if (!matchCase || !Array.isArray(matchCase) || matchCase.length <= 0) return value
		return matchCase[0];
	}

	sortAsc = () => {
		if (this.state.regexp && this.state.items) {
			this.state.items.sort((a, b) => ('' + this.getSortRank(a.snippet.title)).localeCompare(this.getSortRank(b.snippet.title)))
			this.setState({ items: this.state.items })
		}
		this.analytics.logEvent('sort', { listId: this.state.listId, orderBy: 'asc', sortType: this.state.sortType, regex: this.state.regex })
	}

	sortDesc = () => {
		if (this.state.regexp && this.state.items) {
			this.state.items.sort((a, b) => ('' + this.getSortRank(b.snippet.title)).localeCompare(this.getSortRank(a.snippet.title)))
			this.setState({ items: this.state.items })
		}
		this.analytics.logEvent('sort', { listId: this.state.listId, orderBy: 'desc', sortType: this.state.sortType, regex: this.state.regex })
	}

	reverse = () => {
		if (this.state.items) {
			this.setState({ items: this.state.items.reverse() })
		}
		this.analytics.logEvent('sort', { listId: this.state.listId, orderBy: 'reverse', sortType: this.state.sortType, regex: this.state.regex })
	}

	onDragEnd = (resultList) => {
		console.log(resultList)
		this.setState({ items: resultList })
	}

	changeSelectItem = (newValue) => {
		let newReg = null;
		let sortSubHighlight = false
		if (newValue === 0) {
			// 커스텀 
			// strings.custom
		} else if (newValue === 1) {
			// 출시일자 순
			// strings.publishedAt
			sortSubHighlight = true
		} else if (newValue === 2) {
			newReg = "\\d+"
			// strings.title_number
		} else if (newValue === 3) {
			newReg = "\\d{6}"
			// strings.title_number_6
		} else if (newValue === 4) {
			newReg = "\\d{8}"
			// strings.title_number_8
		} else if (newValue === 5) {
			newReg = ".+"
			// strings.title_alphabetically
		} else if (newValue === 6) {
			newReg = "\\d{4}-\\d\\d?-\\d\\d?"
			// strings.title_date_yyyy_mm_dd
		}
		this.setState({
			sortType: newValue,
			sortSubHighlight: sortSubHighlight,
		})
		console.log(sortSubHighlight)
		if (newReg) {
			this.changeRegex(newReg)
			this.changeRegexValue(newReg)
		}
	}

	onChangeCopyType = (newValue) => {
		this.setState({ copyType: newValue })
		if (newValue === 0) { //copyAll //모두복사
			let filterReg = ".+"
			this.setState({
				filter: filterReg,
				filterExp: new RegExp(filterReg, "gm"),
			})
			this.changeFilterValue(filterReg)
		} else if (newValue === 1) { //copyFilter //특정 단어 복사
			let filterReg = ""
			this.setState({
				filter: filterReg,
				filterExp: new RegExp(filterReg, "gm"),
			})
			this.changeFilterValue(filterReg)
		} else if (newValue === 2) { //copyRegex //정규식 복사
			let filterReg = "\\d+"
			this.setState({
				filter: filterReg,
				filterExp: new RegExp(filterReg, "gm"),
			})
			this.changeFilterValue(filterReg)
		}
	}

	onChangeCopyFilter = (newValue) => {
		let regexp = null
		try {
			regexp = new RegExp(newValue, 'gm')
			console.log("success")
		} catch (e) {
			regexp = null
			console.log("false")
		}
		this.setState({ filter: newValue, filterExp: regexp })
	}

	onClickClearList = () => {
		this.analytics.logEvent('clear_list', { listId: this.state.listId, itemCount: this.state.items.length })
		this.setState({ items: [] })
	}

	onClickGenerateList = () => {
		window.scrollTo(0, document.body.scrollHeight);
	}

	onClickCopyAll = () => {
		let array = this.state.items ?? []
		for (let item of this.state.originList) {
			// let regex = new RegExp(this.state.filter, "gm");
			// console.log(`[${regex}] : ${regex.test(item.title)} / ${item.title}`)
			// console.log(item)
			if (new RegExp(this.state.filter, "gm").test(item.snippet.title)) {
				array.push(item)
			}
		}
		this.setState({ items: array })
		this.analytics.logEvent('copy_all', { listId: this.state.listId, copyType: this.state.copyType, regex: this.state.filter })
	}

	onClickSave = async () => {
		const { strings } = this.state
		console.log(this.props.youtubeApi)
		console.log(this.props.youtubeApi.isSignedIn)
		console.log(this.props.youtubeApi._isSignedIn)
		if (this.state.items.length <= 0) {
			this.showToast(strings.step4EmptyList, 'error')
			return
		}
		if (this.props.youtubeApi.isSignedIn()) {
			const progressCallback = (progress, max, element) => {
				this.setState({
					progressContent: `${progress} / ${max}<br />${element}`
				})
			}
			this.showProgress(strings.step4SavePlayListProgress)
			this.analytics.logEvent('generate_start', { listId: this.state.listId, title: this.state.playListTitle, privacyState: this.state.privacyState, itemCount: this.state.items.length })
			try {
				let listId = await this.props.youtubeApi.createPlaylist(this.state.playListTitle, this.state.playListDescription, this.state.privacyState, this.state.items, progressCallback)
				// await this.props.youtubeApi.createPlaylistBatch(this.state.playListTitle, this.state.playListDescription, this.state.privacyState, this.state.items, progressCallback)
				this.showToast(strings.step4Complete, 'success')
				this.analytics.logEvent('generate_finish', { listId: this.state.listId, title: this.state.playListTitle, privacyState: this.state.privacyState, resultId: listId, itemCount: this.state.items.length })
			} catch (e) {
				if (e.status === 403 && e.result && e.result.error && Array.isArray(e.result.error.errors) && e.result.error.errors.length > 0 && e.result.error.errors[0].reason === "quotaExceeded") {
					this.showToast(strings.errorApiLimit, 'error', true)
					this.analytics.logEvent('generate_failed_limit_exceed', { listId: this.state.listId, title: this.state.playListTitle, privacyState: this.state.privacyState, itemCount: this.state.items.length, error: e })	
				} else {
					console.log(e)
					this.showToast(strings.errorOccurred + " : " + e, 'error')
					this.analytics.logEvent('generate_failed', { listId: this.state.listId, title: this.state.playListTitle, privacyState: this.state.privacyState, itemCount: this.state.items.length, error: e })	
				}
			}
			this.closeProgress()
		} else {
			this.props.youtubeApi.signIn()
		}
	}

	onClickCopyElement = (item) => {
		console.log(item)
		this.state.items.push(item)
		this.setState({ items: this.state.items })
		this.analytics.logEvent('copy_element', { listId: this.state.listId, item: item.id })
	}

	onClickDeleteElement = (item) => {
		console.log(item)
		const itemToFind = this.state.items.find((e) => { return e.id === item.id && e.etag === item.etag })
		const idx = this.state.items.indexOf(itemToFind)
		if (idx > -1) {
			this.state.items.splice(idx, 1)
			this.setState({ items: this.state.items })
		}
		this.analytics.logEvent('remove_element', { listId: this.state.listId, item: item.id })
	}

	onChangePrivacyState = (state) => {
		const { cookies } = this.props
		cookies.set('privacyState', state, { path: '/' })
		this.setState({ privacyState: state })
		console.log(state)
	}

	showToast = (message, variant, persist) => {
		//success, error,warning and info
		let options = {
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'center',
			}
		}
		if (variant) options.variant = variant
		if (persist) options.persist = persist
		this.props.enqueueSnackbar(message, options)
	}

	showProgress = (title, content) => {
		this.setState({
			progressTitle: title,
			progress: true,
			progressContent: content,
		})
	}

	closeProgress = () => {
		this.setState({ progress: false })
	}

	render() {
		const { strings } = this.state
		const { classes } = this.props;
		return (
			<>
				<Helmet
					htmlAttributes={{
						lang: strings.metaLanguage
					}}>
					<title>{strings.seoAppTitle}</title>
					<meta name="keywords" content={strings.metaKeywords} />
					<meta name="description" content={strings.metaDescription} />
					<meta name="author" content={strings.metaAuthor} />
					<meta name="robots" content={strings.metaRobots} />
					<meta http-equiv="content-language" content={strings.metaLanguage} />
					<link rel="canonical" href={strings.metaCanonical} />
					<meta property="og:type" content={strings.ogType} />
					<meta property="og:title" content={strings.ogTitle} />
					<meta property="og:description" content={strings.ogDescription} />
					<meta property="og:image" content={strings.ogImage} />
					<meta property="og:url" content={strings.ogUrl} />
					<html lang={strings.metaLanguage} />
				</Helmet>
				<div className={classes.root} theme={createMuiTheme({ palette: { type: "dark" /* light */ } })}>
					<Dialog
						open={!this.state.cookieAccept}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"					>
						<DialogTitle id="alert-dialog-title">{"Cookie policy"}</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
							This website uses cookies to ensure you get the best experience on our website <a href={"/cookie-policy.html"}>More info</a>
					</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.onAcceptCookie} color="primary" autoFocus>
								Agree
						  </Button>
						</DialogActions>
					</Dialog>
					<Dialog aria-labelledby="simple-dialog-title" open={this.state.progress}>
						<div className={classes.progressDialog}>
							<DialogTitle id="simple-dialog-title">{this.state.progressTitle}</DialogTitle>
							<DialogContent style={{ display: this.state.progressContent ? 'block' : 'none' }} dangerouslySetInnerHTML={{ __html: this.state.progressContent }} />
							<CircularProgress style={{ margin: 20 }} />
						</div>
					</Dialog>
					<Box className={classes.appBar}>
						<Typography variant="h1" style={{ display: "inline-block", fontFamily: "Noto Sans CJK KR", fontSize: 28, fontWeight: "bold", color: "#505050" }}>
							<b>{strings.appTitle}</b>
						</Typography>

						<Box className={classes.appBarMenu}>
							<FormControl className={classes.formControl} style={{ float: "right", display: "inline-block" }}>
								<Select
									value={this.state.language}
									onChange={(event) => this.onChangeLanguage(event.target.value)}
								// displayEmpty
								// className={classes.selectEmpty}
								// inputProps={{ 'aria-label': 'Without label' }}
								>
									<MenuItem value="en">English</MenuItem>
									<MenuItem value="ko">한국어</MenuItem>
									{/* <MenuItem value="cn">China</MenuItem>
										<MenuItem value="jp">Japan</MenuItem> */}
								</Select>
								{/* <FormHelperText>Without label</FormHelperText> */}
							</FormControl>
							<Button style={{ color: "#212121", float: "right" }} onClick={() => this.toggleAuth()}>{this.state.isSignIn ? strings.logout : strings.login}</Button>
						</Box>
					</Box>
					<Box className={classes.mainBar}>
						<Typography variant="h2" style={{ fontFamily: "Noto Sans CJK KR", fontSize: 36, fontWeight: "black", color: "#FFFFFF", paddingTop: 16, lineHeight: "54px" }}>
							<b>{strings.mainTitle}</b>
						</Typography>
						<Typography variant="h3" style={{ fontFamily: "Noto Sans CJK KR", fontSize: 24, fontWeight: "light", color: "#FFFFFF", paddingTop: 16, lineHeight: "36px" }}>
							{strings.mainDescription.split('\n').map(line => { return (<>{line}<br /></>) })}
						</Typography>
						<Box style={{ width: 650, display: "inline-block", marginTop: 10 }}>
							<DebounceTextField
								id="playlist-id"
								label={strings.step1TextLabel}
								value={this.state.playlistId}
								style={{ width: "80%", color: "white", borderBottomColor: "white" }}
								InputProps={{ style: { color: "white" }, classes: { underline: classes.underline } }}
								InputLabelProps={{ color: "white" }}
								onChange={(value) => this.setState({ playlistId: value })}
							/>
							&nbsp; &nbsp;
							<Button style={{
								background: "white",
								color: "#505050",
								fontFamily: "Noto Sans CJK KR",
								fontWeight: "bold",
								paddingLeft: 17,
								paddingRight: 17,
								verticalAlign: "bottom",
							}}
								onClick={this.load}
							>
								{strings.load}
							</Button>
						</Box>
					</Box>
					<Box display={this.state.isLoadSuccess ? 'none' : 'block'} className={classes.contentWrapper} style={{ textAlign: 'center' }}>
						<Grid container spacing={4} style={{ maxWidth: 1300, display: 'inline-flex' }}>
							<Grid item xs={4}>
								<Paper className={classes.introPaper}>
									<Typography variant="h4" className={classes.introPaperTitle}>
										{strings.introLeftTitle}
									</Typography>
									<div className={classes.introPaperIconWrapper} >
										<SortByAlphaIcon style={{ fontSize: 120, }} />
									</div>
									<div className={classes.introPaperDescription}>
										{strings.introLeftDescription}
									</div>
								</Paper>
							</Grid>
							<Grid item xs={4}>
								<Paper className={classes.introPaper}>
									<Typography variant="h4" className={classes.introPaperTitle}>
										{strings.introCenterTitle}
									</Typography>
									<div className={classes.introPaperIconWrapper} >
										<FilterListIcon style={{ fontSize: 120, }} />
									</div>
									<div className={classes.introPaperDescription}>
										{strings.introCenterDescription}
									</div>
								</Paper>
							</Grid>
							<Grid item xs={4}>
								<Paper className={classes.introPaper}>
									<Typography variant="h4" className={classes.introPaperTitle}>
										{strings.introRightTitle}
									</Typography>
									<div className={classes.introPaperIconWrapper} >
										<FreeBreakfastIcon style={{ fontSize: 120, }} />
									</div>
									<div className={classes.introPaperDescription}>
										{strings.introRightDescription}
									</div>
								</Paper>
							</Grid>
						</Grid>
					</Box>
					<Box display={this.state.isLoadSuccess ? 'block' : 'none'} className={classes.contentWrapper}>
						<Grid container className={classes.content} spacing={3}>
							<Grid item xs={6}>
								<Box border={1} borderColor={'#DDDDDD'} borderRadius={5} className={classes.listWrapper}>
									<Typography variant="h6" className={classes.title} style={{ color: "#212121", fontWeight: 'bold', textAlign: 'center' }}>
										{strings.filterTitle}
									</Typography>
									<Grid container spacing={1} style={{ marginTop: 12 }}>
										<Grid item xs={3}>
											<FormControl fullWidth variant="outlined">
												<InputLabel id="copy-type-label">{strings.copyType}</InputLabel>
												<Select
													labelId="copy-type-label"
													id="copy-type"
													value={this.state.copyType}
													onChange={(event) => this.onChangeCopyType(event.target.value)}
													label={strings.copyType}>
													<MenuItem value={0}>{strings.copyAll}</MenuItem>
													<MenuItem value={1}>{strings.copyFilter}</MenuItem>
													<MenuItem value={2}>{strings.copyRegex}</MenuItem>
												</Select>
											</FormControl>
										</Grid>
										<Grid item xs={7}>
											<FormControl fullWidth>
												<DebounceTextField
													id="filter"
													disabled={this.state.copyType === 0}
													label={strings.filter}
													value={this.state.filter}
													changeValue={fn => this.changeFilterValue = fn}
													onChange={(value) => this.onChangeCopyFilter(value)}
													helperText={strings.copyType}
												/>
											</FormControl>
										</Grid>
										<Grid item xs={2}>
											<FormControl fullWidth>
												<Button variant="outlined" onClick={this.onClickCopyAll}>{strings.copy}</Button>
											</FormControl>
										</Grid>
										<Grid item xs={12}>
											{
												this.state.originList.map((item, index) => (
													<div style={{ marginBottom: 8 }}>
														<ListItem
															regexp={this.state.filterExp}
															isCopy={true}
															onClickCopy={this.onClickCopyElement}
															data={item}
														/>
													</div>
												))
											}
											{/* <DraggableList
												items={this.state.originList}
												regexp={this.state.filterExp}
												isCopy={true}
												onClickCopy={this.onClickCopyElement}
											/> */}
										</Grid>
									</Grid>
								</Box>
							</Grid>
							<Grid item xs={6}>
								<Box border={1} borderColor={'#DDDDDD'} borderRadius={5} className={classes.listWrapper}>
									<Typography variant="h6" className={classes.title} style={{ color: "#212121", fontWeight: 'bold', textAlign: 'center' }}>
										{strings.sortTitle}
									</Typography>
									<Grid container spacing={1} style={{ marginTop: 12 }}>
										<Grid item xs={3}>
											<FormControl fullWidth variant="outlined">
												<InputLabel id="sort-type-label">{strings.sortType}</InputLabel>
												<Select
													labelId="sort-type-label"
													id="sort-type"
													value={this.state.sortType}
													onChange={(event) => this.changeSelectItem(event.target.value)}
													label={strings.sortType}>
													<MenuItem value={0}>{strings.custom}</MenuItem>
													<MenuItem value={1}>{strings.publishedAt}</MenuItem>
													<MenuItem value={2}>{strings.title_number}</MenuItem>
													<MenuItem value={3}>{strings.title_number_6}</MenuItem>
													<MenuItem value={4}>{strings.title_number_8}</MenuItem>
													<MenuItem value={5}>{strings.title_alphabetically}</MenuItem>
													<MenuItem value={6}>{strings.title_date_yyyy_mm_dd}</MenuItem>
												</Select>
											</FormControl>
										</Grid>
										<Grid item xs={9}>
											<FormControl fullWidth variant="outlined">
												<DebounceTextField
													id="grap-regex"
													label="Regex"
													value={this.state.regex}
													changeValue={fn => this.changeRegexValue = fn}
													onChange={(value) => this.changeRegex(value)}
													helperText="Syntex of regex"
												/>
											</FormControl>
										</Grid>
										<Grid container item xs={12} spacing={1}>
											<Grid item xs={3}>
												<FormControl fullWidth>
													<Button variant="outlined" onClick={this.sortAsc}> {strings.asc} </Button>
												</FormControl>
											</Grid>
											<Grid item xs={3}>
												<FormControl fullWidth>
													<Button variant="outlined" onClick={this.sortDesc}> {strings.desc} </Button>
												</FormControl>
											</Grid>
											<Grid item xs={3}>
												<FormControl fullWidth>
													<Button variant="outlined" onClick={this.reverse}> {strings.reverse} </Button>
												</FormControl>
											</Grid>
											<Grid item xs={3}>
												<FormControl fullWidth>
													<Button variant="outlined" onClick={this.onClickClearList}> {strings.clearList} </Button>
												</FormControl>
											</Grid>
										</Grid>
										<Grid item xs={12}>
											<DraggableList
												items={this.state.items}
												regexp={this.state.regexp}
												isDelete={true}
												subHighlight={this.state.sortSubHighlight}
												onClickDelete={this.onClickDeleteElement}
												onDragEnd={this.onDragEnd}
											/>
										</Grid>
									</Grid>
								</Box>
							</Grid>
							<Grid item xs={12} style={{ display: this.state.isLoadSuccess ? 'block' : 'none' }}>
								<Box border={1} borderColor={'#DDDDDD'} borderRadius={5} className={classes.listWrapper}>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<Typography variant="h6" className={classes.title} style={{ color: "#212121", fontWeight: 'bold', textAlign: 'center' }}>
												{strings.savePlaylistTitle}
											</Typography>
										</Grid>
										<Grid item xs={2} style={{ marginTop: 12 }}>
											<FormControl fullWidth>
												<InputLabel id="privacy-state-label">{strings.step4PrivacyState}</InputLabel>
												<Select
													labelId="privacy-state-label"
													id="privacy-state"
													value={this.state.privacyState}
													onChange={(event) => this.onChangePrivacyState(event.target.value)}
													helperText={strings.step4PrivacyStateHelp}
													label={strings.step4PrivacyStateDescription}>
													<MenuItem value={"private"}>{strings.private}</MenuItem>
													<MenuItem value={"unlisted"}>{strings.unlisted}</MenuItem>
													<MenuItem value={"public"}>{strings.public}</MenuItem>
												</Select>
											</FormControl>
										</Grid>
										<Grid item xs={8}>
											<FormControl fullWidth variant="outlined">
												<DebounceTextField
													id="playlistTitle"
													label={strings.title}
													value={this.state.playListTitle}
													onChange={(value) => this.setState({ playListTitle: value })}
													helperText={strings.step4TitleHelp}
												/>
											</FormControl>
										</Grid>
										<Grid item xs={2}>
											<FormControl fullWidth>
												<Button variant="outlined" onClick={this.onClickSave}> {strings.save} </Button>
											</FormControl>
										</Grid>
										<Grid item xs={12}>
											<FormControl fullWidth variant="outlined">
												<DebounceTextField
													id="playlistDescription"
													label={strings.step4DescriptionTitle}
													value={this.state.playListDescription}
													onChange={(value) => this.setState({ playListDescription: value })}
													helperText={strings.step4DescriptionHelpText}
												/>
											</FormControl>
										</Grid>
									</Grid>
								</Box>
							</Grid>
						</Grid>
					</Box>
					<Grid container>
						<Grid item xs={12} style={{ backgroundColor: "#dddddd", height: 200, display: 'table' }}>
							<div style={{ verticalAlign: 'middle', display: 'table-cell', textAlign: 'center' }}>
								Copyrightⓒ2020 Xort All rights reserved.
									<p />
								<div><a href={"/privacy-policy.html"}>Privacy Policy</a> | <a href={"/term-conditions.html"}>Term of Service</a> | <a href={"/cookie-policy.html"}>Cookie Policy</a></div>
							</div>
						</Grid>
					</Grid>
				</div>
			</>
		);
	}
}

export default withCookies(withStyles(styles)(withSnackbar(Main)));