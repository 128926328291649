import React from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ListItem from '../component/ListItem'

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const grid = 8;

// padding: grid * 2,
const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: "none",

	margin: `0 0 ${grid}px 0`,
	// change background colour if dragging
	background: isDragging ? "lightgreen" : "white",

	// styles we need to apply on draggables
	...draggableStyle
});

const getListStyle = isDraggingOver => ({
	background: isDraggingOver ? "lightblue" : "white",
	padding: grid,
	// width: 250
});

class DraggableList extends React.Component {
	constructor(props) {
		super(props)
		this.onDragEnd = this.onDragEnd.bind(this);
	}

	onDragEnd(result) {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		const items = reorder(
			this.props.items,
			result.source.index,
			result.destination.index
		);

		if (this.props.onDragEnd) {
			this.props.onDragEnd(items)
			//this.props.onDragEnd.call(items)
		}
		// this.setState({
		// 	items
		// });
	}

	render() {
		console.log(this.props.subHighlight)
		return (
			<DragDropContext onDragEnd={this.onDragEnd}>
				<Droppable droppableId="droppable">
					{(provided, snapshot) => (
						<div
							{...provided.droppableProps}
							ref={provided.innerRef}
							style={getListStyle(snapshot.isDraggingOver)}
						>
							{/* {this.state.items.map((item, index) => ( */}
							{this.props.items.map((item, index) => (
								<Draggable key={`${item.id}-${item.etag}`} draggableId={`${item.id}-${item.etag}`} index={index}>
									{(provided, snapshot) => (
										<div
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											style={getItemStyle(
												snapshot.isDragging,
												provided.draggableProps.style
											)}
										>
											<ListItem
												regexp={this.props.regexp}
												isCopy={this.props.isCopy}
												isDelete={this.props.isDelete}
												onClickCopy={this.props.onClickCopy}
												onClickDelete={this.props.onClickDelete}
												subHighlight={this.props.subHighlight}
												data={item}
											/>
										</div>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		);
	}
}

export default DraggableList;