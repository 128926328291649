export default {
	// Common
	load: "Load",
	title: "Title",
	description: "Description",
	copy: "Copy",
	save: "Save",
	errorOccurred: "An error has occurred.",
	errorApiLimit: "Oops! Xort exceeded today's API limit. Please try again tomorrow.",

	// Global
	appTitle: "XORT",
	login: "Login",
	logout: "Logout",

	// Intro
	mainTitle: "YouTube playlist sorting online utility",
	mainDescription: "Easily flip or sort playlists.\nAfter work, save and create your own playlists.\nXort is free.",
	introLeftTitle: "Sort YouTube playlists",
	introLeftDescription: "Quickly and easily sort your YouTube playlists.",
	introCenterTitle: "Playlist filter",
	introCenterDescription: "Filter only videos that contain specific words or meet your criteria.",
	introRightTitle: "Free to use",
	introRightDescription: "Xort is FREE! Get started right away!",

	// SEO
	seoAppTitle: "Xort - Youtube playlist sort tool",
	metaKeywords: "Xort, Playlist, Sort, Youtube, Tool, Util, Reverse, Online, Free, 소터, 플레이리스트, 정렬, 유튜브, 툴, 유틸, 뒤집기, 온라인, 무료",
	metaDescription: "Youtube playlist sorting, flipping, Xort is a free online utility that quickly and easily sorts or flips items in YouTube playlists, provides a variety of free features such as conditional copying or sorting by date.",
	metaAuthor: "Xort",
	metaRobots: "all",
	metaCanonical: "https://xort.app/",
	metaLanguage: "en-US",
	ogType: "website",
	ogTitle: "Xort - Youtube playlist sort tool",
	ogDescription: "Youtube playlist sort, online free utility to quickly and easily sort or flip YouTube playlists",
	ogImage: "https://xort.app/logo512.png",
	ogUrl: "https://xort.app/",

	// Step 1
	loadTitle: "Load list (Step 1)", // Data source
	step1TextLabel: "Data source", // Data source
	step1TextDescription: "Playlist ID or URL", //Playlist ID or URL
	step1LoadList: "Loading playlist ...",
	errorLoadList: "The playlist cannot be loaded. Please check your playlist ID or URL.",

	// Step 2
	filterTitle: "Copy Filter (Step 2)",
	copyType: "Copy method",
	copyAll: "Copy all",
	copyFilter: "Word copy",
	copyRegex: "Custom (Regex)",
	filter: "Filter",
	
	// Step 3
	sortTitle: "Sort (Step 3)",
	sortType: "Sort method",
	publishedAt: "PublishedAt",
	title_number: "Title - Numeric",
	title_number_6: "Title - Numeric 6",
	title_number_8: "Title - Numeric 8",
	title_alphabetically: "Title - Alphabetically",
	title_date_yyyy_mm_dd: "Title - Date (YYYY-MM-DD)",
	custom: "Custom",
	asc: "Asc",
	desc: "Desc",
	reverse: "Reverse",
	generateList: "Generate List",
	clearList: "Clear List",
	
	// Step 4
	savePlaylistTitle: "Save playlist (Step 4)",
	private: "private",
	public: "public",
	unlisted: "unlisted",
	step4PrivacyStateHelp: "Whether the playlist is public",
	step4SavePlayListProgress: "Saveing playlist ...",
	step4TitleHelp: "Title",
	step4DescriptionTitle: "Playlist Title",
	step4DescriptionHelpText: "Platlist Description",
	step4Complete: "The list has been saved successfully.",
	step4PrivacyState: "PrivacyState",
	step4PrivacyStateDescription: "Determines the visibility of the playlist.",
	step4EmptyList: "The playlist is empty."
}