export default {
	// Common
	load: "불러오기",
	title: "제목",
	description: "설명",
	copy: "복사",
	save: "저장",
	errorOccurred: "오류가 발생하였습니다",
	errorApiLimit: "Oops! Xort가 오늘의 API한도를 초과하였습니다. 내일 다시 시도해 주세요.",
	
	// Global
	appTitle: "XORT",
	login: "로그인",
	logout: "로그아웃",

	// Intro
	mainTitle: "유튜브 플레이리스트 정렬 온라인 유틸리티",
	mainDescription: "재생목록을 손쉽게 뒤집거나 정렬하세요.\n작업 이후 저장하여 자신만의 재생목록을 만들어 보세요.\nXort는 무료로 제공됩니다.",
	introLeftTitle: "유튜브 재생목록 정렬",
	introLeftDescription: "유튜브의 재생목록을 쉽고 빠르게 정렬합니다.",
	introCenterTitle: "재생목록 필터",
	introCenterDescription: "특정 단어가 포함되거나 조건에 맞는 영상만 필터합니다.",
	introRightTitle: "무료사용",
	introRightDescription: "Xort는 무료입니다! 바로 시작해 보세요!",

	// SEO
	seoAppTitle: "Xort - Youtube playlist sort tool",
	metaKeywords: "Xort, Playlist, Sort, Youtube, Tool, Util, Reverse, Online, Free, 소터, 플레이리스트, 정렬, 유튜브, 툴, 유틸, 뒤집기, 온라인, 무료",
	metaDescription: "유튜브 플레이리스트 정렬, 뒤집기, Xort는 쉽고 빠르게 유튜브 재생목록의 아이템들을 정렬하거나 뒤집는 온라인 무료 유틸리티입니다, 조건부 복사나 날짜 순 정렬 등 다양한 기능 무료 제공.",
	metaAuthor: "Xort",
	metaRobots: "all",
	metaCanonical: "https://xort.app/",
	metaLanguage: "ko-KR",
	ogType: "website",
	ogTitle: "Xort - Youtube playlist sort tool",
	ogDescription: "유튜브 플레이리스트 정렬, 쉽고 빠르게 유튜브 재생목록을 정렬하거나 뒤집는 온라인 무료 유틸리티",
	ogImage: "https://xort.app/logo512.png",
	ogUrl: "https://xort.app/",

	// Step 1
	loadTitle: "불러오기 (Step 1)", // Data source
	step1TextLabel: "데이터 출처", // Data source
	step1TextDescription: "재생목록 ID 또는 주소(URL)", //Playlist ID or URL
	step1LoadList: "목록을 불러오는중 ...",
	errorLoadList: "재생목록을 불러올 수 없습니다. 재생목록 ID나 URL을 확인해 주세요.",

	// Step 2
	filterTitle: "필터 (Step 2)",
	copyType: "복사방법",
	copyAll: "모두복사",
	copyFilter: "특정단어 복사",
	copyRegex: "커스텀",
	filter: "조건",
	
	// Step 3
	sortTitle: "정렬 (Step 3)",
	sortType: "정렬방법",
	publishedAt: "등록일자순",
	title_number: "제목 - 숫자순",
	title_number_6: "제목 - 숫자 6자리",
	title_number_8: "제목 - 숫자 8자리",
	title_alphabetically: "제목 - 가나다순",
	title_date_yyyy_mm_dd: "제목 - 날짜순 (YYYY-MM-DD)",
	custom: "커스텀",
	asc: "오름차순",
	desc: "내림차순",
	reverse: "뒤집기",
	generateList: "목록 만들기",
	clearList: "목록 비우기",
	
	// Step 4
	savePlaylistTitle: "재생목록 저장 (Step 4)",
	private: "비공개",
	public: "공개",
	unlisted: "미공개",
	step4PrivacyStateHelp: "재생목록의 공개 여부",
	step4SavePlayListProgress: "목록을 저장하는 중...",
	step4TitleHelp: "제생목록의 제목",
	step4DescriptionTitle: "설명",
	step4DescriptionHelpText: "제생목록의 설명",
	step4Complete: "성공적으로 목록이 저장되었습니다.",
	step4PrivacyState: "공개상태",
	step4PrivacyStateDescription: "재생목록의 공개상태를 결정합니다.",
	step4EmptyList: "재생목록이 비어있습니다."
}