
import { gapi } from 'gapi-script';
import bent from 'bent'
import queryString from 'query-string'
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics"

//?=PL4bDVqNQ4hSTgIOQuttq6qz9oMcwidbVA&=snippet&maxResults=50
const get = bent('GET', 200, "https://www.googleapis.com")

// const getList = async (listId) => {
// 	let items = []
// 	let query = {
// 		playlistId: listId,
// 		key: process.env.REACT_APP_YOUTUBE_KEY,
// 		part: "snippet",
// 		maxResults: 50
// 	}
// 	let response = await get(`/youtube/v3/playlistItems?${queryString.stringify(query)}`)
// 	let content = await response.json()
// 	if (Array.isArray(content.items)) {
// 		for (let item of content.items) {
// 			let data = item
// 			console.log(item)
// 			data.title = item.snippet.title
// 			items.push(data)
// 		}
// 	}
// 	return items
// }

// const getList2 = async (listId) => {
// 	// smc play list PL4bDVqNQ4hSTgIOQuttq6qz9oMcwidbVA
// 	// pangten play list PL5jCWAVC_lq7WNAh-yMiMKYT1r0YT2bLH

// 	client.youtube.playlists.list({
// 		part: "status,snippet",
// 		resource: {
// 			snippet: {
// 				title: this.state.playListTitle,
// 				description: this.state.playListDescription
// 			},
// 			status: {
// 				privacyStatus: 'private' // private, public, unlisted // https://developers.google.com/youtube/v3/docs/videos#status.privacyStatus
// 			}
// 		}
// 	})
// }

class YoutubeApi {
	constructor(options) {
		const scope = "https://www.googleapis.com/auth/youtube";
		const discoveryDocs = ["https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest"]

		this.clientId = options.oAuth2Id ?? options.clientId
		this._isSignedIn = false
		this._onUpdateSigninStatus = options.onSignInStatusChange

		gapi.load("client:auth2", () => {
			gapi.client.init({
				clientId: options.oAuth2Id,
				scope: scope,
				discoveryDocs: discoveryDocs
			}).then(() => {
				gapi.auth2.getAuthInstance().isSignedIn.listen(this.updateSigninStatus)
				this.updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get())
				console.log("Ready")
				console.log(gapi.auth2.getAuthInstance().isSignedIn)
			}).catch((err) => {
				console.error(err)
			})
		})
	}

	setOnSignInStatusChangeListener = (callback) => {
		this._onUpdateSigninStatus = callback;
	}

	updateSigninStatus = (isSignedIn) => {
		this._isSignedIn = isSignedIn
		if (this._onUpdateSigninStatus) this._onUpdateSigninStatus(isSignedIn)
	}

	isSignedIn = () => this._isSignedIn

	getPlaylistItems = async (playlistId, pageToken) => {
		// let params = {
		// 	part: "snippet",
		// 	type: "youtube#video",
		// 	playlistId: playlistId,
		// 	maxResults: 50,
		// 	key: this.clientId,
		// }
		// if (pageToken) params.pageToken = pageToken;
		// return await gapi.client.youtube.playlists.list(params)
		// let items = []
		let query = {
			playlistId: playlistId,
			key: process.env.REACT_APP_YOUTUBE_KEY,
			part: "snippet",
			maxResults: 50
		}
		if (pageToken) query.pageToken = pageToken;
		let response = await get(`/youtube/v3/playlistItems?${queryString.stringify(query)}`)
		let content = await response.json()
		// if (Array.isArray(content.items)) {
		// 	for (let item of content.items) {
		// 		let data = item
		// 		data.title = item.snippet.title
		// 		items.push(data)
		// 	}
		// }
		// return items
		return content;
	}

	getPlaylistAll = async (playlistId) => {
		let results = []
		let response = await this.getPlaylistItems(playlistId, null)
		for (let item of response.items) {
			results.push(item)
		}
		while (response.nextPageToken) {
			response = await this.getPlaylistItems(playlistId, response.nextPageToken)
			for (let item of response.items) {
				results.push(item)
			}
		}
		return results
	}

	createPlaylist = async (title, description, privacyStatus, items, progressCallback) => {
		let response = await gapi.client.youtube.playlists.insert({
			part: "status,snippet",
			snippet: {
				title: title,
				description: description
			},
			status: {
				privacyStatus: privacyStatus // private, public, unlisted // https://developers.google.com/youtube/v3/docs/videos#status.privacyStatus
			}
		})
		let playlist = response.result
		console.log(playlist)
		let playlistId = playlist.id

		for (let idx in items) {
			let item = items[idx]
			// let apiRequest = client.youtube.playlistItems.insert({
			let res = await gapi.client.youtube.playlistItems.insert({
				part: "snippet",
				resource: {
					snippet: {
						playlistId: playlistId,
						resourceId: {
							kind: "youtube#video",
							videoId: item.snippet.resourceId.videoId,
							position: idx,
						}
					}
				}
			})
			if (progressCallback) progressCallback(idx, items.length, item.snippet.title)
			console.log(res)
		}
		return playlistId
	}

	createPlaylistBatch = async (title, description, privacyStatus, items, progressCallback) => {
		let response = await gapi.client.youtube.playlists.insert({
			part: "status,snippet",
			snippet: {
				title: title,
				description: description
			},
			status: {
				privacyStatus: privacyStatus // private, public, unlisted // https://developers.google.com/youtube/v3/docs/videos#status.privacyStatus
			}
		})
		let playlist = response.result
		console.log(playlist)
		let playlistId = playlist.id
		let batch = gapi.client.newBatch();
		for (let idx in items) {
			let item = items[idx]
			let apiRequest = gapi.client.youtube.playlistItems.insert({
				part: "snippet",
				resource: {
					snippet: {
						playlistId: playlistId,
						resourceId: {
							kind: "youtube#video",
							videoId: item.snippet.resourceId.videoId,
							position: idx,
						}
					}
				}
			})
			batch.add(apiRequest)
		}
		batch.then(res => {
			console.log(res)
		}, err => {
			console.error(err)
		})
	}

	_isUserEqual = (googleUser, firebaseUser) => {
		if (firebaseUser) {
			var providerData = firebaseUser.providerData;
			for (var i = 0; i < providerData.length; i++) {
				if (providerData[i].providerId === firebase.auth.GoogleAuthProvider.PROVIDER_ID &&
					providerData[i].uid === googleUser.getBasicProfile().getId()) {
					// We don't need to reauth the Firebase connection.
					return true;
				}
			}
		}
		return false;
	}

	signIn = () => {
		gapi.auth2.getAuthInstance().signIn().then(googleUser => {
			let unsubscribe = firebase.auth().onAuthStateChanged((firebaseUser) => {
				unsubscribe();
				console.log(googleUser.getAuthResponse())
				let credential = firebase.auth.GoogleAuthProvider.credential(googleUser.getAuthResponse().id_token);
				firebase.auth().signInWithCredential(credential).then(function(result) {
					console.log(result)
				}, function(err)  {
					console.error(err)
				})
				if (!this._isUserEqual(googleUser, firebaseUser)) {
					firebase.app().analytics().logEvent('sign_up', {method: "Google"})
				} else {
					firebase.app().analytics().logEvent('login', {method: "Google"})
				}
			})
			console.log(googleUser)
			console.log(googleUser.getAuthResponse())
			//if (this._onUpdateSigninStatus) this._onUpdateSigninStatus(true)
		})
	}

	signOut = () => {
		gapi.auth2.getAuthInstance().signOut().then(googleUser => {
			console.log(googleUser) //undefined
			//if (this._onUpdateSigninStatus) this._onUpdateSigninStatus(false)
		})
	}
}

export default YoutubeApi